import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import placeholderImg from "../../assets/images/blog/blog-img7.jpg"
import { Badge } from "react-bootstrap"

const BlogCard = ({ post  }) => {
  return (
      <div className="single-blog-post bg-fffbf5">
        <div className="post-image">
            {
              post.frontmatter.featuredimage ?
                <Link to={post.fields.slug}> <GatsbyImage alt={post.frontmatter.title}
                             image={getImage(post.frontmatter.featuredimage)} /> </Link> :
                <img className="blog-placeholder-image" src={placeholderImg} alt="banner" />
            }
        </div>

        <div className="post-content" style={{display: "flex", flexDirection: "column"}}>
          <div className="justify tag-date-container" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" , marginBottom: "10px", paddingRight: "6px", marginTop: "15px"}}>
            <div className="tag-container">
              {post.frontmatter.tags && post.frontmatter.tags.slice(0,3).map((tag) => (
                <Badge key={tag} pill variant="primary" style={{marginRight:"3px"}}>{tag}</Badge>
              ))}
            </div>
            <p className={"mb-0"} style={{ fontSize: "14px" }}>{post.frontmatter.date} </p>
          </div>
          <h3 className={"h4 blog-preview-title"} style={{ fontSize: "22px"}}>
              {post.frontmatter.title}
          </h3>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", flex:"auto", marginBottom: "20px"}}>
            <div className="post-text-preview">
              <p style={{ fontSize: "16px", textAlign: "justify" }}>{post.frontmatter.preview ?? post.excerpt}</p>
            </div>
            {
              post.frontmatter.moreinfolink && (
                <div className="blog-link"><a href={post.frontmatter.moreinfolink} target="_blank" rel="noreferrer"
                      className={"text-primary"}>{post.frontmatter.moreinfotext}</a></div>)
            }
            {
              !post.frontmatter.kurznews && (
                <div className="blog-link"><a href={post.fields.slug} style={{color: "#614508"}}>Zum Blogartikel</a></div>)
            }
          </div>
        </div>
      </div>
  )
}

export default BlogCard
